<template>
  <v-app id="logoscorp">
    <loading :loading="isLoading" />
    <div class="bg">
      <div class="text text-center">
        <span class="text__white font-weight-bold">{{$t('maintenance_title')}}</span> <br><br>
        <span class="text__white">{{$t('maintenance_message')}}</span>
      </div>
    </div>
</v-app>  
</template>

<script>
import { setVeeLocale } from '@/utils'
import { mapActions } from 'vuex'
import LanguageService from '@/services/language'
import Loading from '@/components/commons/Loading'
export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true
    try {
      await this.populateLanguages()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('language', ['setLanguages', 'setLanguage', 'setIdiom']),
    async populateLanguages() {
      const params = {
        limit: 999,
        'filter[active]': 1,
        'fields[languages]': 'id,code,name',
        include: 'translations'
      }
      const { data } = await LanguageService.fetch(params)
      const languages = data.data.map(item => {
        let messages = {}
        item.translations.forEach(item => {
          messages[item.label] = item.translation
        })
        return { ...item, translations: messages }
      })

      this.setLanguages(languages)

      // Broswer Language
      let lang = navigator.language.substr(0, 2)
      let defaultLang = languages.find(item => item.code === lang)
      if (!defaultLang) {
        defaultLang = languages.find(item => item.code === 'es')
        lang = 'es'
      }

      // Set Browser languages
      this.setIdiom(defaultLang)
      setVeeLocale(lang)
    }
  }
}
</script>

<style scoped>
.logo {
  max-width: 100%;
  height: auto;
  padding: 0 20px 0 20px;
}

.bg {
  display: grid;
  place-content: center;
  background-image: url('../assets/av_404_2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.text {
  text-transform: uppercase;
}

.text__white {
  color: white;
  font-size: 2.5rem;
}

@media screen and (min-width: 768px) { 
  .logo {
    padding: 0;
  }

  .text__white {
    font-size: 3.5rem;
  }

}

</style>