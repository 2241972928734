<template>
  <v-app id="logoscorp">
    <!-- DRAWER -->
    <AppDrawer />
    <!-- TOOLBAR -->
    <AppToolBar />
    <div class="bg">
      <div class="logo_container">
        <div class="text">
          <span class="text__white">{{$t('portal_404_word1')}}</span> <br>
          <span class="text__blue font-weight-bold">{{$t('portal_404_word2')}}</span>
        </div>
        <img
          src="../assets/404_desktop_portrait.png"
          alt="404 Av"
          class="logo"
        />
      </div>
    </div>
</v-app>  
</template>

<script>
import AppToolBar from '@/components/core/AppToolBar'
import AppDrawer from '@/components/core/AppDrawer'
export default {
  components: {
    AppToolBar,
    AppDrawer
  }
}
</script>

<style scoped>
.logo {
  max-width: 100%;
  height: auto;
  padding: 0 20px 0 20px;
}

.bg {
  display: grid;
  place-content: center;
  background-image: url('../assets/av_404_2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.text {
  text-transform: uppercase;
  margin-left: 20.5%;
  line-height: 1;
}

.text__white {
  color: white;
  font-size: 1.5rem;
}

.text__blue {
  color: #0080C8;
  font-size: 2rem;
}

@media screen and (min-width: 768px) { 
  .logo {
    padding: 0;
  }

  .text {
    margin-left: 18%;
  }

  .text__white {
    font-size: 2.5rem;
  }

  .text__blue {
    font-size: 3rem;
  }
}

</style>