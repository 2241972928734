<template>
  <v-app class="bg-login" id="auth">
    <loading :loading="isLoading" />
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col hidden-sm-and-down"
            :class="handlePublication() ? ' left__publication' : 'left__av'"
            :style="!isLoading ? background : ''"
            cols="12"
            md="6"
          >
            <div v-if="handlePublication()" class="publication__text">
              <div v-html="publication.content" />
            </div>
            <div v-else class="left__text">
              <div v-if="!broker.id  && !isLoading">
                <p class="uppercase">{{ $t('login_knowledge') }}</p>
                <p class="uppercase">{{ $t('login_&_reliability') }}</p>
              </div>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col v-if="!isLoading" class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    :src="logo"
                    :alt="altLogo"
                    :class="{'logo-container__image': !broker.id}"
                  />
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <v-card elevation="0" v-if="passWordReset === false">
                  <v-card-text>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmit">
                        <v-row class="px-md-5">
                          <v-col cols="12">
                            <!-- EMAIL FIELD -->
                            <ValidationProvider
                              :name="$t('login_email')"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="$t('login_email')"
                                v-model="user.email"
                                :hint="$t('login_email_hint')"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                          <!-- CURRENT PASSWORD -->
                          <v-col cols="12">
                            <ValidationProvider
                              :name="$t('login_new_password')"
                              rules="required|min:8|confirmed:password"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                name="password"
                                :label="$t('login_new_password')"
                                v-model="user.password"
                                :hint="$t('login_new_password_hint')"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>

                          <!-- NEW PASSWORD -->
                          <v-col cols="12">
                            <ValidationProvider
                              :name="$t('login_new_password_confirm')"
                              rules="required|min:8"
                              vid="password"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="$t('login_new_password_confirm')"
                                v-model="user.password_confirmation"
                                :hint="$t('login_new_password_confirm_hint')"
                                :type="show2 ? 'text' : 'password'"
                                @click:append="show2= !show2"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" v-if="questionSelectedQuantity > 0">
                            <v-row>
                              <v-col cols="12">
                                <p class="logo-container__text">
                                  {{ $t('login_security_questions') }}
                                </p>
                              </v-col>
                            </v-row>
                            <v-row
                              v-for="(question, i) in user.userQuestions"
                              :key="i"
                            >
                              <v-col cols="12" md="12">
                                <label for="">{{
                                  $t(question.question)
                                }}</label>
                                <ValidationProvider
                                  :name="$t('login_security_answer')"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    :label="$t('login_security_answer')"
                                    :placeholder="
                                      $t('login_security_answer_placeholder')
                                    "
                                    v-model="question.answer"
                                    :type="show3 ? 'text' : 'password'"
                                    @click:append="show3 = !show3"
                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :error-messages="errors[0]"
                                  />
                                </ValidationProvider>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-space-between flex-row align-baseline"
                          >
                            <v-spacer></v-spacer>
                            <language-menu class="mx-auto" />
                          </v-col>
                          <v-col v-if="recaptchaShow" cols="12">
                            <v-row justify="center" class="my-5">
                              <vue-recaptcha
                                sitekey="Your key here"
                                :loadRecaptchaScript="true"
                              ></vue-recaptcha>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          class="d-flex flex-column-reverse flex-md-row justify-center align-center"
                          dense
                        >
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              class="mx-md-2 btn-cancel"
                              outlined
                              color="primary"
                              :to="{ name: 'Login', query: { broker: $route.query.broker } }"
                              rounded
                              >{{ $t('btn_cancel') }}</v-btn
                            >
                          </v-col>
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              color="primary"
                              type="submit"
                              :class="{
                                'login-button': !broker.id
                              }"
                              :loading="loading"
                              :disabled="invalid"
                              rounded
                              >{{ $t('login_change_password') }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
                <v-card rounded elevation="0" class="pa-6" v-else>
                  <v-card-text>
                    <v-row justify="center mb-5">
                      <span class="logo-container__text">
                        {{ $t('login_change_password_successful') }}
                      </span>
                    </v-row>

                    <v-row justify="center">
                      <v-btn
                        color="primary"
                        type="submit"
                        :class="{
                          'login-button': !broker.id
                        }"
                        :to="{ name: 'Login', query: { broker: $route.query.broker } }"
                        rounded
                        >{{ $t('login_login') }}</v-btn
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import UserService from '@/services/user'
import SettingsService from '@/services/setting'
import QuestionsService from '@/services/question'
import QuestionUserService from '@/services/questionUser'
import { setVeeLocale } from '@/utils'
import LanguageService from '@/services/language'
import LanguageMenu from '@/components/commons/LanguageMenu'
import Loading from '@/components/commons/Loading'
import BrokerService from '@/services/broker'
import PublicSettingService from '@/services/publicSetting'

export default {
  components: { VueRecaptcha, LanguageMenu, Loading },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      quantityQuestions: null,
      questions: [],
      questionSelectedQuantity: null,
      isLoading: false,
      passWordReset: false,
      recaptchaShow: false,
      user: {
        token: this.$route.query.token,
        email: '',
        password: '',
        password_confirmation: '',
        userQuestions: []
      },
      broker: {},
      publication: {
        date: null,
        content: null
      },
      publicSettings: [],
      whiteBranding: null
    }
  },
  async created() {
    this.isLoading = true
    try {
      await this.getPublicSettings()

      this.user.email = this.$route.query.email

      const broker = this.$route.query.broker
      if (broker && this.whiteBranding) {
        await this.getBrokerInfo(broker)
      }
      await this.populateLanguages()
      // await this.getQueantityQuestions()
      //await this.getRecaptchaVisibility()
      //await this.verifyQuestionsExist()
    } catch (e) {
      this.$dialog.notify.error(e)
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('language', ['idiom']),

    currentDate() {
      return this.$date().format('YYYY-MM-DD')
    },

    logo() {
      return this.broker.id ? `data:image/png;base64,${this.broker.img_desktop}` : require('@/assets/AV_002_logo.jpg')
    },

    altLogo() {
      return this.broker.id ? `${this.broker.name} Logo` : 'AV Agil Logo'
    },

    background() {
      return this.broker.id && this.broker.img_background ? `background-image: url('${this.broker.img_background}')` : `background-image: url('${require('@/assets/AV_001_inicio_sinletras.jpg')}')`
    }
  },
  watch: {
    idiom(newVal) {
      const content = this.publicSettings.find(
        item => item.key == `publications_content_${newVal.code}`
        )?.value
      this.$set(this.publication, 'content', content ?? null) 
    }
  },
  methods: {
    ...mapActions('language', ['setLanguages', 'setLanguage', 'setIdiom']),

    async handleSubmit() {
      try {
        await QuestionUserService.getUserAnswers(this.user.userQuestions)
        await UserService.updatePassword(this.user)
        this.passWordReset = true
      } catch (e) {
        this.$dialog.notify.error(e)
      }
    },
    async populateQuestions() {
      const { data } = await QuestionsService.fetch({
        'filter[active]': 1,
        limit: 999
      })
      this.questions = data.data
      for (let i = 0; i < this.questions.length; i++) {
        let indiceAleatorio = Math.floor(Math.random() * (i + 1))
        let temporal = this.questions[i]
        this.questions[i] = this.questions[indiceAleatorio]
        this.questions[indiceAleatorio] = temporal
      }
    },
    async getQueantityQuestions() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'quantity_questions'
      })
      this.quantityQuestions = data.data[0].value
    },
    async getRecaptchaVisibility() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'recaptcha'
      })
      this.recaptchaShow = data.data[0].value
    },
    populateUserQuestions() {
      let quantity = 0
      if (this.quantityQuestions > this.questionSelectedQuantity) {
        quantity = this.quantityQuestions - this.questionSelecteds.length
      } else {
        quantity =
          this.quantityQuestions > this.questions.length
            ? this.questions.length
            : this.quantityQuestions
      }

      for (let i = 0; i < quantity; i++) {
        this.user.userQuestions.push({
          question_id: this.questions[i].id,
          question: this.questions[i].question,
          answer: '',
          user_id: this.user.id
        })
      }
    },
    async verifyQuestionsExist() {
      const { data } = await UserService.fetch({
        'filter[email]': this.user.email,
        include: 'questions'
      })

      this.user.id = data.data[0].id
      this.questionSelectedQuantity = data.data[0].questions.length

      if (this.questionSelectedQuantity > 0) {
        await this.populateQuestions()
        await this.populateUserQuestions()
      }
    },
    async populateLanguages() {
      const params = {
        'filter[active]': 1,
        'fields[languages]': 'id,code,name',
        include: 'translations'
      }
      const { data } = await LanguageService.fetch(params)
      const languages = data.data.map(item => {
        let messages = {}
        item.translations.forEach(item => {
          messages[item.label] = item.translation
        })
        return { ...item, translations: messages }
      })

      this.setLanguages(languages)

      // Broswer Language
      const lang = navigator.language.substr(0, 2)
      const defaultLang = languages.find(item => item.code === lang)

      // Set Browser languages
      this.setIdiom(defaultLang)
      setVeeLocale(lang)
    },

    async getBrokerInfo(code) {
      this.isLoading = true
      try {
        const { data } = await BrokerService.getBrokerInfo(code)
        this.broker = data
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false
      }
    },

    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.publication.date = data.find(item => item.key == 'publications_date')?.value
      this.publication.content = data.find(item => item.key == `publications_content_${this.idiom.code}`)?.value
      this.whiteBranding = data.find(item => item.key == 'white_branding')?.value

      this.publicSettings = data
    },

    handlePublication() {
      return this.handlePublicationDate() 
        && this.handlePublicationContent() 
        && !this.broker.id 
        && !this.isLoading
    },

    handlePublicationDate() {
      return (this.publication.date !== '' && this.publication.date !== null) && this.publication.date >= this.currentDate 
    },

    handlePublicationContent() {
      return this.publication.content !== null && this.publication.content !== ''
    },
  }
}
</script>
<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgb(255, 255, 255);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left__av {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}

.left__publication {
  background-image: url('../../assets/AV_004_inicio_portal_clientes_sinletra.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 50px;
}
.left__text {
  width: 50px;
  margin-left: -200px;
  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}

.publication__text {
  color: white;
  width: 100vh;
}

.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 85%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>