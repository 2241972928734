var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"bg-login",attrs:{"id":"auth"}},[_c('loading',{attrs:{"loading":_vm.isLoading}}),_c('v-main',[_c('v-container',{staticClass:"login-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"login-container__row",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"login-container__col hidden-sm-and-down",class:_vm.handlePublication() ? ' left__publication' : 'left__av',style:(!_vm.isLoading ? _vm.background : ''),attrs:{"cols":"12","md":"6"}},[(_vm.handlePublication())?_c('div',{staticClass:"publication__text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.publication.content)}})]):_c('div',{staticClass:"left__text"},[(!_vm.broker.id  && !_vm.isLoading)?_c('div',[_c('p',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.$t('login_knowledge')))]),_c('p',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.$t('login_&_reliability')))])]):_vm._e()])]),(!_vm.isLoading)?_c('v-col',{staticClass:"login-container__col right",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('div',{staticClass:"logo-container d-flex flex-column justify-center align-center"},[_c('img',{class:{'logo-container__image': !_vm.broker.id},attrs:{"src":_vm.logo,"alt":_vm.altLogo}})])]),_c('v-col',{attrs:{"cols":"10","offset":"1"}},[(_vm.passWordReset === false)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-row',{staticClass:"px-md-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('login_email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('login_email')) + " *"),"hint":((_vm.$t('login_email')) + " *"),"error-messages":errors[0]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('login_new_password'),"rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password","label":((_vm.$t('login_new_password')) + " *"),"hint":((_vm.$t('login_new_password')) + " *"),"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors[0]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('login_new_password_confirm'),"rules":"required|min:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('login_new_password_confirm')) + " *"),"hint":((_vm.$t('login_new_password_confirm')) + " *"),"type":_vm.show2 ? 'text' : 'password',"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors[0]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-space-between flex-row align-baseline",attrs:{"cols":"12"}},[_c('v-spacer'),_c('language-menu',{staticClass:"mx-auto"})],1),_c('v-btn',{class:{
                            'login-button': !_vm.broker.id
                          },attrs:{"color":"primary","type":"submit","rounded":"","loading":_vm.loading,"disabled":invalid}},[_vm._v(_vm._s(_vm.$t('btn_establish')))])],1),_c('br')],1)]}}],null,false,3138732355)})],1)],1):_c('v-card',{staticClass:"pa-6",attrs:{"rounded":"","elevation":"0"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"logo-container__text"},[_vm._v(" "+_vm._s(_vm.$t('login_config_password_successful'))+" ")])]),_c('br'),_c('br'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{class:{
                        'login-button': !_vm.broker.id
                      },attrs:{"color":"primary","type":"submit","to":{ name: 'Login', query: { broker: _vm.$route.query.broker } },"rounded":""}},[_vm._v(_vm._s(_vm.$t('login_login')))])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }